// https://codepen.io/daveguck/pen/meameV

import React, { Component } from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import * as kontaktStyles from '../styles/kontakt.module.css'

const EMAIL = 'kontakt@musicspot.com.pl'

class KontaktPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imie: '',
      email: '',
      wiadomosc: '',
    }
  }

  componentDidMount() {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-467307250/2sa_CIXB8IYDEPKV6t4B',
      })
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })

    console.log(this.state)
  }

  handleSubmit(event, message) {
    // event.preventDefault();
    // let formData = new FormData()
    // formData.append('imie', this.state.imie)
    // formData.append('email', this.state.email)
    // formData.append('wiadomosc', this.state.wiadomosc)
    // // formData.append('_gotcha', this.state.??);
    // console.log('Submitting', formData);
    // fetch('//formspree.io/chris32345@mailnesia.com', {
    //   body: formData,
    //   method: 'post',
    // }).then(() => {
    //   console.log('successfully submitted');
    // }).catch((err) => {
    //   console.log('there was an error')
    // });
  }

  render() {
    return (
      <>
        <SEO title="Kontakt - MusicSpot" />

        <section className="sub-page-header" style={{ padding: '30px' }}>
          <div className="wrapper">
            <h1>Skontaktuj się z&nbsp;nami</h1>
          </div>
        </section>

        <section className={kontaktStyles.contactSectoin}>
          <div className="wrapper" style={{ position: 'relative' }}>
            <div className={kontaktStyles.contactContent}>
              <h3>Music Spot - Edukacja Muzyczna</h3>
              <p><a href="tel:690 343 069">tel. 690 343 069</a></p>

              <div className={kontaktStyles.contactAddressWrapper}>
                <p className={kontaktStyles.contactAddressLeft}>
                  ul. św. Wojciecha 6 <br />
                  JAWORZNO
                </p>
                <p>
                  ul. Chorzowska 108 <br />
                  KATOWICE
                </p>
              </div>
              <form
                className={kontaktStyles.contactForm}
                onSubmit={(e, m) => this.handleSubmit(e, m)}
                action={`https://formspree.io/${EMAIL}`}
                method="POST"
              >
                <div className={kontaktStyles.contactFormRow}>
                  <div
                    className={kontaktStyles.inputContainer}
                    style={{ marginRight: '20px' }}
                  >
                    <label htmlFor="imie">Imię:</label>
                    <input
                      type="text"
                      name="imie"
                      placeholder=""
                      tabIndex="1"
                      className={kontaktStyles.input}
                      onChange={e => this.handleChange(e)}
                    />
                  </div>
                  <div
                    className={kontaktStyles.inputContainer}
                    style={{ marginRight: '20px' }}
                  >
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      name="email"
                      placeholder=""
                      tabIndex="2"
                      className={kontaktStyles.input}
                      onChange={e => this.handleChange(e)}
                    />
                  </div>
                  <div className={kontaktStyles.inputContainer}>
                    <label htmlFor="email">Telefon:</label>
                    <input
                      type="tel"
                      name="telefon"
                      placeholder=""
                      tabIndex="3"
                      className={kontaktStyles.input}
                      onChange={e => this.handleChange(e)}
                    />
                  </div>
                </div>
                <input type="text" name="_gotcha" style={{ display: 'none' }} />
                <div
                  className={kontaktStyles.inputContainer}
                  style={{ marginTop: '20px' }}
                >
                  <label htmlFor="wiatomosc">Wiadomość:</label>
                  <textarea
                    rows="4"
                    name="wiadomosc"
                    placeholder=""
                    tabIndex="4"
                    className={kontaktStyles.input}
                    onChange={e => this.handleChange(e)}
                  />
                </div>
                <button type="submit">Wyślij</button>
              </form>
            </div>
            <div className={kontaktStyles.arrowDown} />
          </div>
        </section>
        <section>
          <div className={kontaktStyles.contactMapWrapper}>
            <iframe
              style={{ marginRight: "20px" }}
              className="actAsDiv"
              width="100%"
              height="434"
              frameBorder="0"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2553.590935273339!2d19.272211912197346!3d50.20617677142785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716c3a3956e117b%3A0xe7fe508335f5c38d!2sMusic%20Spot%20-%20Profesjonalna%20Nauka%20Gry%20na%20Gitarze%20i%20Lekcje%20%C5%9Apiewu%20w%20Jaworznie!5e0!3m2!1spl!2spl!4v1696182637036!5m2!1spl!2spl" 
            />{' '}
            <iframe
              className="actAsDiv"
              width="100%"
              height="434"
              frameBorder="0"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2550.2662656834345!2d19.002627076546545!3d50.26828677155797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716cf62f64afccf%3A0x883785058d06300c!2sMusic%20Spot%20-%20Profesjonalna%20Nauka%20Gry%20na%20Gitarze%20i%20Lekcje%20%C5%9Apiewu%20w%20Katowicach!5e0!3m2!1spl!2spl!4v1696182547693!5m2!1spl!2spl" 
            />{' '}
          </div>
        </section>
      </>
    )
  }
}

export default KontaktPage
