// extracted by mini-css-extract-plugin
export var arrowDown = "kontakt-module--arrow-down--EG6FC";
export var contactAddressLeft = "kontakt-module--contact-address-left--kT3gF";
export var contactAddressWrapper = "kontakt-module--contact-address-wrapper--0KUhW";
export var contactContent = "kontakt-module--contact-content--1a8i9";
export var contactForm = "kontakt-module--contact-form--wPqOy";
export var contactFormRow = "kontakt-module--contact-form-row--4jvfe";
export var contactMapWrapper = "kontakt-module--contact-map-wrapper--mpACE";
export var contactSectoin = "kontakt-module--contact-sectoin--hAQ3u";
export var input = "kontakt-module--input--3-3ZP";
export var inputContainer = "kontakt-module--input-container--AOccv";